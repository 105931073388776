import React, { useState, useEffect } from "react";
import Question from "./Question";
import { useNavigate } from "react-router-dom";
import { m, LazyMotion, domAnimation, AnimatePresence } from "framer-motion";
import ClipLoader from "react-spinners/ClipLoader";
import "./Quiz.css";
import { ReactComponent as LeftArrow } from "../images/leftArrow.svg";
import { ReactComponent as RightArrow } from "../images/rightArrow.svg";

function generateUUID() {
  return crypto.randomUUID();
}

const Quiz = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [responses, setResponses] = useState([]);
  const [email, setEmail] = useState("");
  const [hasStarted, setHasStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const uuid = generateUUID();

  useEffect(() => {
    setIsLoading(true);

    fetch("https://quiz.markmanson.workers.dev/api/questions?quiz_id=1")
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data);
        setResponses(
          data.map((q) => ({
            question_id: q.question_id,
            selected_answer_id: null,
          }))
        );

        setIsLoading(false);
      });
  }, []);

  const handleAnswerSelected = (answer) => {
    console.log("answer", answer);
    const updatedResponses = [...responses];
    updatedResponses[currentQuestionIndex].selected_answer_id =
      answer.answer_id;
    setResponses(updatedResponses);
    handleNext();
  };

  const getLatestAnsweredQuestionIndex = () => {
    for (let i = responses.length - 1; i >= 0; i--) {
      if (responses[i].selected_answer_id !== null) return i;
    }
    return -1;
  };

  const handleNext = () => {
    // Ensure the current question is answered before proceeding
    if (
      responses[currentQuestionIndex].selected_answer_id !== null &&
      currentQuestionIndex < questions.length
    ) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleSubmit = () => {
    console.log("submitting responses", responses);
    fetch("https://quiz.markmanson.workers.dev/api/responses", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, uuid, responses }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.emailExists) {
          navigate(`/result?uuid=${uuid}`);
        } else {
          navigate("/unknown-email-page");
        }
      });
  };

  return (
    <div className="center-content">
      <div className="container">
        <LazyMotion features={domAnimation}>
          <AnimatePresence mode="wait">
            <m.div
              key={currentQuestionIndex}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}>
              {!hasStarted && (
                <button
                  onClick={() => {
                    setCurrentQuestionIndex(0);
                    setHasStarted(true);
                  }}>
                  Start Quiz
                </button>
              )}

              {hasStarted && isLoading && (
                <ClipLoader loading={isLoading} size={50} />
              )}

              {hasStarted &&
                !isLoading &&
                currentQuestionIndex !== null &&
                currentQuestionIndex < questions.length && (
                  <>
                    <Question
                      question={questions[currentQuestionIndex]}
                      onAnswerSelected={handleAnswerSelected}
                    />
                    <div className="navigation-icons">
                      {currentQuestionIndex > 0 && (
                        <LeftArrow
                          className="navigation-icon left-arrow"
                          onClick={handlePrevious}
                        />
                      )}
                      {currentQuestionIndex <=
                        getLatestAnsweredQuestionIndex() && (
                        <RightArrow
                          className="navigation-icon right-arrow"
                          onClick={handleNext}
                        />
                      )}
                    </div>
                  </>
                )}

              {hasStarted &&
                !isLoading &&
                currentQuestionIndex === questions.length && (
                  <>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button onClick={handleSubmit}>Submit</button>
                  </>
                )}
            </m.div>
          </AnimatePresence>
        </LazyMotion>
      </div>
    </div>
  );
};

export default Quiz;
