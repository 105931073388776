import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Quiz from "./components/Quiz";
import Result from "./components/Result";

const UnknownEmailPage = () => {
  return (
    <div className="confirm-page">
      Please verify your email address to see the results.
    </div>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Quiz />} />
        <Route path="/result" element={<Result />} />
        <Route path="/unknown-email-page" element={<UnknownEmailPage />} />
      </Routes>
    </Router>
  );
}

export default App;
