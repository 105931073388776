import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Result.css";

function Result() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const uuid = params.get("uuid");

  useEffect(() => {
    fetch(`https://quiz.markmanson.workers.dev/api/scores?uuid=${uuid}`)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result && result.total !== undefined) {
          setData(result);
        } else {
          throw new Error("Invalid data format");
        }
      })
      .catch((err) => {
        setError(
          "Sorry, there was a problem fetching your results. Please try again later."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [uuid]);

  if (loading) {
    return null; // Render nothing while waiting for the API response.
  }

  if (error) {
    return <div>{error}</div>; // Render the error message if there's an error.
  }

  return (
    <div>
      <h1>Total Score: {data.total}</h1>
      {data.categories.map((category, index) => (
        <div key={index}>
          <h2>
            {category.category}: {category.score}
          </h2>
        </div>
      ))}
    </div>
  );
}

export default Result;
