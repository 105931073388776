import React from "react";

const Question = ({ question, onAnswerSelected }) => {
  return (
    <div>
      <h2>{question.question_text}</h2>
      {question.options.map((option, index) => (
        <button key={index} onClick={() => onAnswerSelected(option)}>
          {option.answer_text}
        </button>
      ))}
    </div>
  );
};

export default Question;
